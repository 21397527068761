import React from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate  } from "react-router-dom";

const MenuLink = ({ to, onClick, children }) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    if (onClick) onClick();
    navigate(to);
  };

  return (
    <li onClick={handleClick} style={{ cursor: 'pointer' }}>
      {children}
    </li>
  );
};

export default function BurgerMenu({ showBurgerMenu, setShowBurgerMenu }) {
  return (
    <div className={showBurgerMenu ? "burger-menu" : "hidden-burger-menu"}>
      <ul>
        <IoMdClose
          onClick={() => setShowBurgerMenu(false)}
          style={{ alignSelf: "flex-end", marginRight: 10 }}
          size={25}
          className="burger-icon-close cursor-pointer"
        />
        <MenuLink to="/" onClick={() => setShowBurgerMenu(false)}>
          Home
        </MenuLink>
        <MenuLink to="/about-us" onClick={() => setShowBurgerMenu(false)}>
          About Us
        </MenuLink>
        <MenuLink to="/contact-us" onClick={() => setShowBurgerMenu(false)}>
          Contact Us
        </MenuLink>
      </ul>
    </div>
  );
}
