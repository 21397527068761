import React from "react";
import { FaDiscord } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { AiFillYoutube } from "react-icons/ai";

export default function Footer() {
  return (
    <div className="footer">
      <p
        style={{
          textAlign: "center",
        }}
      >
        © Copyright 2024 Cipher01. All rights reserved.
      </p>

      <ul
        style={{
          display: "flex",
          color: "white",
          listStyle: "none",
          gap: 15,
          alignItems: "center",
        }}
      >
        {/* <li>
          <FaDiscord size={25} />
        </li>
        <li>
          <BsLinkedin size={20} />
        </li>
        <li>
          <RiInstagramFill size={25} />
        </li>
        <li>
          <AiFillYoutube size={25} />
        </li> */}
      </ul>
    </div>
  );
}
