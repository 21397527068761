import React, { useRef, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { applyButtonFocusAnimationIndiv } from "../../utils/AnimationUtilitiesIndividual";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function ContactForm() {
  const buttonRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { onMouseEnter, onMouseLeave } = applyButtonFocusAnimationIndiv(buttonRef);

  // Formspree hook
  const [state, handleSubmit] = useForm("mjkblvol");

  // Local state for handling success or error messages
  const [message, setMessage] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha('contact_form'); // Use appropriate action string

      // Create a FormData object
      const formData = new FormData(event.target);
      formData.append("g-recaptcha-response", recaptchaToken);

      // Submit the form data to Formspree
      const response = await fetch(
        "https://formspree.io/f/mjkblvol", // Use your Formspree endpoint
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        setMessage("We will contact you to set up a virtual meeting if required to discuss how we can assist you!");
      } else {
        setMessage("Error submitting form");
      }
    } catch (error) {
      console.error("Error executing reCAPTCHA:", error);
      setMessage("Error executing reCAPTCHA");
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      className="shadow-lg rounded-lg flex flex-col gap-5 justify-between self-stretch"
      style={{ color: "var(--white-smoke)" }}
    >
      <div className="flex flex-col justify-center flex-grow-[2]">
        <label htmlFor="full-name">Full Name</label>
        <input
          type="text"
          name="name"
          id="full-name"
          className="border-[1px] rounded-sm text-black flex-1"
          placeholder="First and Last"
          required
        />
        <ValidationError
          prefix="Full Name"
          field="name"
          errors={state.errors}
        />

        <label htmlFor="email">Email Address</label>
        <input
          id="email"
          type="email"
          name="email"
          className="border-[1px] rounded-sm text-black flex-1"
          placeholder="security@example.com"
          required
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />

        <label htmlFor="service">Chosen Service</label>
        <select
          name="service"
          id="service"
          className="border-[1px] rounded-sm text-gray-400"
          defaultValue="default"
          required
        >
          <option value="default" disabled className="text-black">
            Service
          </option>
          <option value="Penetration Testing" className="text-black">
            Penetration Testing
          </option>
          <option value="Vulnerability Assessment" className="text-black">
            Vulnerability Assessment
          </option>
          <option value="Incident Response" className="text-black">
            Incident Response
          </option>
          <option value="Advanced Digital Forensics" className="text-black">
            Advanced Digital Forensics
          </option>
          <option value="Cyber Consulting" className="text-black">
            Cyber Consulting
          </option>
          <option value="Cyber Insurance" className="text-black">
            Cyber Insurance
          </option>
        </select>
        <ValidationError
          prefix="Service"
          field="service"
          errors={state.errors}
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          className="text-black border-[1px] rounded-sm h-full"
          placeholder="Your Request"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </div>
      <br />
      <button
        ref={buttonRef}
        type="submit"
        disabled={state.submitting}
        className="btn-primary"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        Submit
      </button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default ContactForm;
