import React from "react";

export default function OurStory({ storyRef }) {
  return (
    <div className="our-story" ref={storyRef}>
      <h1 style={{ textAlign: "center" }}>Cipher01 Origins</h1>
      <p
        style={{
          textAlign: "center",
          width: "60%",
          margin: "auto",
          marginTop: 90,
          lineHeight: "2",
        }}
      >
        For over 50 years, Computel has been a trusted name in the IT industry,
        providing reliable solutions and building lasting relationships with
        clients across various sectors. Throughout this journey, Computel has
        witnessed the rapid evolution of technology and the increasing reliance
        on digital assets. As the world grew more connected, Computel began to
        notice a significant shift in the needs of their clients. It wasn't just
        about managing IT infrastructure anymore; the growing threat of
        cyberattacks highlighted the critical need for robust cybersecurity
        measures.
        <br></br>
        Recognizing this, Computel realized that their clients' digital assets
        required more than just traditional IT support. They needed specialized
        protection against an ever-evolving landscape of cyber threats. Driven
        by this realization, Computel decided to take a bold step forward. With
        decades of experience and a deep understanding of their clients' needs,
        they established Cipher01—a new company dedicated to providing
        cutting-edge cybersecurity solutions.
        <br></br>
        Cipher01 was born out of a commitment to safeguarding the
        digital world. It combines Computel's rich heritage with a
        forward-looking approach to cybersecurity, ensuring that their clients'
        most valuable assets are protected against the challenges of today and
        tomorrow.
      </p>
    </div>
  );
}
