import React from "react";
import ContactForm from "../components/ContactUs";
import { Helmet } from "react-helmet-async";

function ContactUs() {
  const height = window.innerHeight;

  return (
    <>
      <Helmet>
        <title>
          Contact Cipher01 - Get in Touch with Our Cybersecurity Experts
        </title>
        <meta
          name="description"
          content="Get in touch with Cipher01 for expert cybersecurity support. Our team is here to answer your questions and assist with your security needs."
        />

        <meta
          name="keywords"
          content="contact us, Cipher01, cybersecurity contact, support, inquiries"
        />
        <meta property="og:title" content="Contact Us - Cipher01" />
        <meta
          property="og:description"
          content="Contact Cipher01 for your cybersecurity needs. Reach out to us through our contact form, email, or phone for any inquiries or support."
        />
        <meta
          property="og:image"
          content="https://cipher01.com/static/media/cyber.49cc0bdd5c4435b5cf9f.png"
        />
        <meta property="og:url" content="https://cipher01.com/#/contact-us" />
        <meta property="og:site_name" content="Cipher01" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Cipher01" />
        <meta
          name="twitter:description"
          content="Contact Cipher01 for your cybersecurity needs. Reach out to us through our contact form, email, or phone for any inquiries or support."
        />
        <meta
          name="twitter:image"
          content="https://cipher01.com/static/media/cyber.49cc0bdd5c4435b5cf9f.png"
        />
        <link rel="canonical" href="https://cipher01.com/#/contact-us" />
      </Helmet>
      <div
        className="contact-us-screen flex flex-col justify-center items-center"
        style={{ height: height }}
      >
        <div className="contact-us-wrapper shadow-lg">
          <h1 style={{ color: "var(--white-smoke)" }}>Contact Us</h1>
          <ContactForm />
        </div>
      </div>
    </>
  );
}

export default ContactUs;
