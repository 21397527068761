import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import LandingPage from "./pages/LandingPage";
import ContactUs from "./pages/ContactUs";
import Layout from "./pages/layout";

function App() {

  return (
    <HashRouter basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<LandingPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
