import gsap from 'gsap';

export const applyButtonFocusAnimationIndiv = (buttonRef) => {
  const handleMouseEnter = () => {
    gsap.to(buttonRef.current, { scale: 1.1, duration: 0.3, backgroundColor: "var(--electric-cyan)"});
  };

  const handleMouseLeave = () => {
    gsap.to(buttonRef.current, { scale: 1, duration: 0.3, backgroundColor: "var(--cyber-blue)"});
  };

  return { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave };
};

export const applyAnchorFocusAnimationIndiv = (anchorRef) => {
  const handleMouseEnter = () => {
    gsap.to(anchorRef.current, { scale: 1.1, duration: 0.3, color: "var(--electric-cyan)"});
  };

  const handleMouseLeave = () => {
    gsap.to(anchorRef.current, { scale: 1, duration: 0.3, color: "var(--white-smoke)"});
  };

  return { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave };
}

export const animatePackageCards = (cardRef) => {
  const handleMouseEnter = () => {
    gsap.to(cardRef.current, {
      scale: 1.7,
      x: window.innerWidth / 2 - cardRef.current.offsetWidth / 2 - cardRef.current.getBoundingClientRect().left,
      y: window.innerHeight / 2 - cardRef.current.offsetHeight / 2 - cardRef.current.getBoundingClientRect().top,
      duration: 0.3,
    });
  };

  const handleMouseLeave = () => {
    gsap.to(cardRef.current, { scale: 1, x: 0, y: 0, duration: 0.3 });
  };


  return { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave };
}