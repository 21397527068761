import React, { useRef } from "react";
import { applyButtonFocusAnimationIndiv } from "../../utils/AnimationUtilitiesIndividual";


export default function LearnMore({storyRef}) {
  const height = window.innerHeight;
  const buttonRef = useRef(null);
  const { onMouseEnter, onMouseLeave } = applyButtonFocusAnimationIndiv(buttonRef);
  const handleButtonClick = () => {
    if (storyRef.current) {
      storyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="about-us" style={{ height: height }}>
      <button ref={buttonRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handleButtonClick} className="btn-primary">What's the story?</button>
    </div>
  );
}
