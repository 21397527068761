import React, { useEffect, lazy } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Home = lazy(() => import("../components/LandingPage/Home"));
const Services = lazy(() => import("../components/LandingPage/Services"));
const Packages = lazy(() => import("../components/LandingPage/Packages"));

export default function LandingPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>
          Cipher01 - Premier Cybersecurity Solutions for Modern Threats
        </title>
        <meta
          name="description"
          content="Cipher01 offers expert cybersecurity solutions to protect your digital assets. Explore our innovative services for optimal security."
        />
        <meta
          name="keywords"
          content="cybersecurity, digital security, IT security, cybersecurity services"
        />
        <meta
          property="og:title"
          content="Cipher01 - Leading Cybersecurity Solutions"
        />
        <meta
          property="og:description"
          content="Cipher01 provides top-notch cybersecurity solutions to safeguard your digital assets. Discover our innovative approach and services tailored for optimal security."
        />
        <meta
          property="og:image"
          content="https://cipher01.com/static/media/ring.154e76abf2e7f100a2dcfccb62045d5c.svg"
        />
        <meta property="og:url" content="https://cipher01.com/" />
        <meta property="og:site_name" content="Cipher01" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Cipher01 - Leading Cybersecurity Solutions"
        />
        <meta
          name="twitter:description"
          content="Cipher01 provides top-notch cybersecurity solutions to safeguard your digital assets. Discover our innovative approach and services tailored for optimal security."
        />
        <meta
          name="twitter:image"
          content="https://cipher01.com/static/media/ring.154e76abf2e7f100a2dcfccb62045d5c.svg"
        />
        <link rel="canonical" href="https://cipher01.com/" />
      </Helmet>
      <div>
        <h1 className="visually-hidden">
          Cipher01 - Premier Cybersecurity Solutions for Modern Threats
        </h1>
        <Home />
        <Services />
        <Packages />
      </div>
    </>
  );
}
