import React, { useEffect, useRef } from "react";
import LearnMore from "../components/AboutUs/LearnMore";
import OurStory from "../components/AboutUs/OurStory";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function AboutUs() {
  const { pathname } = useLocation();
  const storyRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>About Cipher01 - Our Mission and Cybersecurity Expertise</title>
        <meta
          name="description"
          content="Learn more about Cipher01 and our mission to provide top-tier cybersecurity solutions. Discover our team’s expertise and commitment to your security."
        />

        <meta
          name="keywords"
          content="about us, Cipher01, cybersecurity team, company mission"
        />
        <meta property="og:title" content="About Us - Cipher01" />
        <meta
          property="og:description"
          content="Learn more about Cipher01, our mission, and our team dedicated to providing exceptional cybersecurity solutions."
        />
        <meta
          property="og:image"
          content="https://cipher01.com/static/media/wipfli.a886e39545667759b43c.gif"
        />
        <meta property="og:url" content="https://cipher01.com/#/about-us" />
        <meta property="og:site_name" content="Cipher01" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Cipher01" />
        <meta
          name="twitter:description"
          content="Learn more about Cipher01, our mission, and our team dedicated to providing exceptional cybersecurity solutions."
        />
        <meta
          name="twitter:image"
          content="https://cipher01.com/static/media/wipfli.a886e39545667759b43c.gif"
        />
        <link rel="canonical" href="https://cipher01.com/#/about-us" />
      </Helmet>
      <LearnMore storyRef={storyRef} />
      <OurStory storyRef={storyRef} />
      {/* <OurTeam /> */}
    </>
  );
}
