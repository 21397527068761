import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import BurgerMenu from "./LandingPage/Mobile/BurgerMenu";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { applyAnchorFocusAnimationIndiv } from "../utils/AnimationUtilitiesIndividual";
import logo from "../assets/C01.svg";
export default function Navbar({ showBurgerMenu, setShowBurgerMenu }) {
  const [background, setBackground] = useState(false);

  const anchorRefs = [useRef(null),useRef(null),useRef(null), useRef(null)];
  const anchorAnimations = anchorRefs.map((ref) => applyAnchorFocusAnimationIndiv(ref));

  useEffect(() => {
    // Change navbar background on scroll
    const changeBackground = () => {
      window.scrollY >= 60 ? setBackground(true) : setBackground(false);
    };
    window.addEventListener("scroll", changeBackground);
  }, []);

  return (
    <header className={background ? "nav background" : "nav"}>
      {/* === Logo & Title */}
      <Link
        className={background ? "logo-div logo-div-short" : "logo-div"}
        to="/"
        ref={anchorRefs[3]}
        onMouseEnter={anchorAnimations[3].onMouseEnter}
        onMouseLeave={anchorAnimations[3].onMouseLeave}
      >
        <img src={logo} alt="C01 logo"></img>
      </Link>

      {/* === Top right menu === */}
      <ul className="nav-ul">
        <li>

          <NavLink
            ref={anchorRefs[0]}
            onMouseLeave={anchorAnimations[0].onMouseLeave}
            onMouseEnter={anchorAnimations[0].onMouseEnter}
            to={"/"}
            className={({ isActive }) =>
              isActive ? "link activeLink" : "link"
            }
          >
          <FontAwesomeIcon
            icon={faHome}
            size="lg"
          /> Home
          </NavLink>
        </li>
        <li>      
          <NavLink
            ref={anchorRefs[1]}
            onMouseLeave={anchorAnimations[1].onMouseLeave}
            onMouseEnter={anchorAnimations[1].onMouseEnter}          
            to={"about-us"}
            className={({ isActive }) =>
              isActive ? "link activeLink" : "link"
            }
          >
          <FontAwesomeIcon
            icon={faUser}
            size="lg"
          /> About Us
          </NavLink>
        </li>
        <li>
    
          <NavLink
            ref={anchorRefs[2]}
            onMouseLeave={anchorAnimations[2].onMouseLeave}
            onMouseEnter={anchorAnimations[2].onMouseEnter}
            to={"contact-us"}
            className={({ isActive }) =>
              isActive ? "link activeLink" : "link"
            }
          >
          <FontAwesomeIcon
            icon={faPhone}
            size="lg"
          /> Contact Us
          </NavLink>
        </li>
      </ul>

      {/* --- Mobile Burger Menu --- */}
      <BiMenu
        className="burger-icon-open cursor-pointer"
        size={35}
        onClick={() => setShowBurgerMenu(true)}
      />
      <BurgerMenu
        setShowBurgerMenu={setShowBurgerMenu}
        showBurgerMenu={showBurgerMenu}
      />
    </header>
  );
}
